export default [
  {
    path: "/test",
    name: "Test",
    component: () => import("../views/test/test.vue"),
    meta: {
      title: "测试",
    },
  },
  {
    path: "/wechat/test",
    name: "WechatTest",
    component: () => import("../views/test/wechatTest.vue"),
    meta: {
      title: "测试",
    },
  },
];
