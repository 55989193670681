// import { isLogin } from "@/common";

// const verifyLogin = (to, form, next) => {
//   if (isLogin()) {
//     next("/");
//     return;
//   }
//   next();
// };

export default [
  {
    path: "/smsCode",
    name: "Code",
    component: () => import("../views/app/login/sign-in-code.vue"),
    // beforeEnter: verifyLogin,
    meta: {
      title: "获取验证码",
    },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/app/login/sign-in-password.vue"),
    meta: {
      title: "登录",
    },
  },
  {
    path: "/name",
    name: "Name",
    component: () => import("../views/app/login/sign-up-success.vue"),
    meta: {
      title: "填写姓名",
    },
  },
];
