import authWeChatSDK from "./authWeChatSDK";
import { isWeChat } from "@/utils";
const wxOpenApp = (config = null, success) => {
  return new Promise((resolve, reject) => {
    console.log("传入信息", config);
    if (!isWeChat()) return reject({ msg: "请在微信环境下，授权JS-SDK" });
    authWeChatSDK()
      .then((wx) => {
        wx.checkJsApi({
          jsApiList: ["wx-open-launch-app"], // 需要检测的 JS 接口列表，所有 JS 接口列表见附录2,
          success: function (res) {
            console.log("wxJsApi success:", res);
            if (success) {
              success();
            }
            resolve(res);
          },
          fail: (err) => {
            console.log("wxJsApi fail:", err);
            reject(err);
          },
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export default wxOpenApp;
