import wx from "weixin-js-sdk";
import { isWeChat } from "@/utils";
import { getWeChatSign } from "@/api/generic-server";
import { localProxyStorage } from "@/utils/storage";

// 微信SDK授权
const authWeChatSDK = () => {
  return new Promise((resolve, reject) => {
    if (isWeChat()) {
      // let url = isIOS() ? window.entryUrl : location.href.split("#")[0];
      let url = location.href.split("#")[0];
      console.log(url);
      getWeChatSign({ url })
        .then((res) => {
          let { data } = res;

          // 这里请求接口，拿到配置信息
          wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: data.gzhAppId, // 必填，公众号的唯一标识
            timestamp: data.timestamp, // 必填，生成签名的时间戳
            nonceStr: data.noncestr, // 必填，生成签名的随机串
            signature: data.signature, // 必填，签名
            jsApiList: [
              "hideMenuItems",
              "onMenuShareTimeline",
              "onMenuShareAppMessage",
              "onMenuShareQQ",
              "onMenuShareQZone",
            ],
            openTagList: ["wx-open-launch-app"],
            // 必填，需要使用的JS接口列表
          });

          // 授权状态，默认成功
          let authStatus = true;
          localProxyStorage.auth = { authStatus: true };
          /*
           * 微信提供了wx.ready函数，虽然文档上说wx.ready是验证成功时执行的函数，但权限验证成功或失败都会调用。也就是说
           * 如果权限验证通过，只会调用wx.ready函数，如果权限验证失败，先调用wx.error函数，然后再调用wx.ready。
           * 所以在wx.ready里调用接口也不保险，我们需要在真正验证时才调用接口。
           * */
          wx.ready(() => {
            // 为true 说明没执行 wx.error没执行，则说明授权成功`
            if (authStatus) {
              console.log("微信SDK授权成功");
              resolve(wx);
            }
          });

          wx.error((error) => {
            localProxyStorage.auth = { authStatus: false };
            console.error("微信SDK授权失败：", localProxyStorage.auth, error);
            authStatus = false;
            reject(error);
          });
        })
        .catch(() => {
          localProxyStorage.auth = { authStatus: false };
        });
    } else {
      reject({ msg: "请在微信环境下，授权JS-SDK" });
      localProxyStorage.auth = { authStatus: false };
    }
  });
};

export default authWeChatSDK;
