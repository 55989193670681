// # 环境变量

const networkProtocol = window.location.protocol;

// # 域名地址
export const baseURL = networkProtocol + process.env["VUE_APP_BASE_URL"];

// # OSS地址
export const ossURL = networkProtocol + process.env["VUE_APP_OSS_URL"];
export const ossURL321 = networkProtocol + process.env["VUE_APP_OSS_URL"] + "/321/2023";

// 候鸟基金oss图片地址
export const ossURLWelfare =
  networkProtocol + process.env["VUE_APP_OSS_URL"] + "/sys/img/sj-img-12.30/";

export const apiURL = networkProtocol + process.env["VUE_APP_API_URL"];

// # OSS临时文件域名
export const ossTempURL = networkProtocol + process.env["VUE_APP_OSS_TEMP_URL"];
