<template>
  <div class="download-box">
    <div class="download-left">
      <div class="image">
        <img :src="require('@/assets/images/login/download_logo.png')" alt="logo" />
      </div>
      <div class="text-box">
        <div class="title-text">上进青年App</div>
        <div class="subtitle-text">跑步读书上进习惯养成社区</div>
      </div>
    </div>
    <div class="download-right">
      <div v-if="!isWx || !isLogin()" class="download-btn" @click="downloadApp">下载App</div>
      <div v-else class="download-btn" @click="setCount">
        下载App
        <wx-open-launch-app
          class="download-app"
          @launch="handleLaunch"
          @error="handleError"
          :appid="appId"
          :extinfo="extinfo"
        >
          <component :is="'script'" type="text/wxtag-template">
            <div style="height: 36px; width: 80px"></div>
          </component>
        </wx-open-launch-app>
      </div>
    </div>
    <GuideMask :showFlag="showFlag" @close-mask="closeMask"></GuideMask>
  </div>
</template>
<script>
export default { name: "DownloadApp" };
</script>
<script setup>
import { ref, computed, onMounted } from "vue";
import { checkH5InnerUserAgent } from "@/utils/index";
import GuideMask from "./components/GuideMask.vue";
import { appId } from "@/common/index";
import { isWeChatEnv, isWeChat, pushLogin } from "@/utils";
import wxOpenApp from "@/utils/weChat/openApp";
import useOpenAppStore from "@/store/openApp";
import { localProxyStorage } from "@/utils/storage";
import { isLogin } from "@/common/index";

// 打卡app的路由
const openAppStore = useOpenAppStore();

const extinfo = computed(() => {
  return openAppStore.getExtinfo();
});
const handleError = (val) => {
  downloadApp();
  console.log("error", val, val.detail, extinfo);
};
const handleLaunch = (val, val1) => {
  console.log("success", val, val1, extinfo);
};

const emit = defineEmits(["isWxClick", "count"]);
const setCount = () => {
  emit("count");
};

const isWx = ref(isWeChatEnv());
onMounted(() => {
  if (isWeChat()) {
    wxOpenApp()
      .then((res) => {
        console.log("wxOpenApp:", res, localProxyStorage.auth);
        // if (isWx.value === undefined) {
        //   location.reload();
        // }
        isWx.value = true;
        if (localProxyStorage.auth) {
          isWx.value = localProxyStorage.auth?.authStatus;
        }
        emit("isWxClick", isWx.value);
      })
      .catch((err) => {
        console.log("wxOpenApp:", err, localProxyStorage.auth);
        isWx.value = false;
        if (localProxyStorage.auth) {
          isWx.value = localProxyStorage.auth?.authStatus;
        }
        emit("isWxClick", isWx.value);
        // if (isWx.value === undefined) {
        //   location.reload();
        // }
      });
  }
});
const showFlag = ref(false);
const closeMask = () => {
  showFlag.value = false;
};

const downloadApp = () => {
  setCount(); // 统计下载数据
  console.log("isWx", isWx.value, isWeChatEnv());
  if (pushLogin()) return; // 新增是否登录判断
  const openMask = checkH5InnerUserAgent();
  if (openMask) {
    showFlag.value = true;
  }
};
</script>

<style lang="scss" scoped>
.download-box {
  width: 3.75rem;
  height: 0.56rem;
  position: fixed;
  top: 0;
  z-index: 100;
  padding: 0 0.14rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  .download-left {
    display: flex;
    .image {
      width: 0.35rem;
      height: 0.35rem;
      img {
        width: 100%;
        height: auto;
      }
    }
    .text-box {
      margin-left: 0.08rem;
    }
    .title-text {
      color: #170606;
      line-height: 0.227rem;
    }
    .subtitle-text {
      font-size: 0.12rem;
      line-height: 0.12rem;
      color: #7e7e80;
    }
  }
  .download-right {
    .download-btn {
      padding: 0.07rem 0.11rem;
      background: linear-gradient(150deg, #fa8a14 0%, #f65c15 100%);
      border-radius: 0.2rem;
      color: #fff;
      font-size: 0.13rem;
      font-weight: 600;
      position: relative;
      .download-app {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
}
</style>
