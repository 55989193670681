import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vant from "@/utils/vant.config";
import directive from "@/directives/install";
import VConsole from "vconsole";
import components from "./components/index.js";
// css样式引入
import "normalize.css";
import "./styles/index.scss";
import sa from "sa-sdk-javascript";

let app = createApp(App);
const saConfig = {
  server_url: process.env.VUE_APP_TRACK_SERVER_URL, // 注册地址
  is_track_single_page: true, // 单页面配置，默认开启，若页面中有锚点设计，需要将该配置删除，否则触发锚点会多触发 $pageview 事件
  use_client_time: true,
  app_js_bridge: true, // 与App打通，默认只有 App 数据接收地址与 H5 相同时，才可以打通成功 https://manual.sensorsdata.cn/sa/latest/app-h5-1573914.html
  // 默认 image 支持使用 'ajax' 和 'beacon'
  send_type: "image",
  // 开启批量发送
  batch_send: false,
  heatmap: {
    //是否开启点击图，default 表示开启，自动采集 $WebClick 事件，可以设置 'not_collect' 表示关闭。
    clickmap: "default",
    //是否开启触达图，not_collect 表示关闭，不会自动采集 $WebStay 事件，可以设置 'default' 表示开启。
    scroll_notice_map: "not_collect",
    // collect_tags 配置其他元素类型的元素点击事件采集
    collect_tags: {},
  },
  // 日志输出功能
  show_log:
    process.env.VUE_APP_RUN_ENV === "development" ||
    process.env.VUE_APP_RUN_ENV === "testing" ||
    process.env.VUE_APP_RUN_ENV === "pre",
};

// 初始化神策
sa.init(saConfig);
// 注册公共属性
sa.registerPage({
  $platform: "web_h5",
  $project: "sjqn",
});

sa.quick("autoTrack");

app.config.globalProperties.$sensors = sa; // 挂载神策SDK到全局实例上

app.config.globalProperties.$sensorsTrack = function (eventName, properties) {
  // 发送埋点事件
  this.$sensors.track(eventName, properties);
};

app.use(store).use(router).use(vant).use(directive).use(components);

// vue3 路由已经是异步的了，这里要处理一下 等待路由 ready好后，再挂载程序
router.isReady().then(() => app.mount("#app"));

if (
  process.env.VUE_APP_RUN_ENV === "development" ||
  process.env.VUE_APP_RUN_ENV === "testing" ||
  process.env.VUE_APP_RUN_ENV === "pre"
) {
  new VConsole();
}

// 百度埋点统计
// if (process.env.VUE_APP_RUN_ENV === "production") {
//   var _hmt = _hmt || [];
//   window._hmt = _hmt; // 将_hmt挂载到window下
//   (function () {
//     var hm = document.createElement("script");
//     hm.src = "https://hm.baidu.com/hm.js?1a94c5d4ea9cfd1fd0b26a4680bb3cd9";
//     var s = document.getElementsByTagName("script")[0];
//     s.parentNode.insertBefore(hm, s);
//   })();
//   router.beforeEach((to, from, next) => {
//     if (_hmt) {
//       if (to.path) {
//         _hmt.push(["_trackPageview", "/#" + to.fullPath]);
//       }
//     }
//     next();
//   });
// }

// 注入用户标识信息,提供给 webFunny 监控平台
// if (process.env.VUE_APP_RUN_ENV === "production" || process.env.VUE_APP_RUN_ENV === "pre") {
//   webfunny.injectUserInfo();
// }
