import { defineStore } from "pinia";
import { localProxyStorage } from "@/utils/storage";

const useOpenAppStore = defineStore({
  id: "OpenApp", // id必填，且需要唯一
  state: () => {
    return {
      // extinfo: "",
      extinfo: localProxyStorage?.app?.extinfo || "youthapp://youth.cn/page/app/Main?tab=habit",
    };
  },
  actions: {
    setExtinfo(extinfo) {
      localProxyStorage.app = Object.assign(localProxyStorage?.app || {}, {
        extinfo,
      });
      this.extinfo = extinfo;
    },
    getExtinfo() {
      return this.extinfo;
    },
  },
});

export default useOpenAppStore;
