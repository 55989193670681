// 分享页 帖子详情
export default [
  {
    path: "/page/circle/CircleDetail",
    name: "sharePostDetail",
    component: () => import("../views/app/dynamic/dynamic-detail/index.vue"),
    meta: {
      title: "上进青年",
    },
  },
  {
    path: "/page/information/InformationDetail",
    name: "shareNewsDetail",
    component: () => import("../views/app/dynamic/information/index.vue"),
    meta: {
      title: "上进青年",
    },
  },
];
