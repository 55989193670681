import { appPublicRefresh } from "@/common";
// 排行榜
export default [
  {
    path: "/page/colleague/rankingList",
    name: "colleagueRanking",
    beforeEnter: appPublicRefresh,
    component: () => import("../views/app/ranking/colleague/index.vue"),
    meta: {
      title: "",
    },
  },
  {
    path: "/page/run/rankingList",
    name: "runRanking",
    beforeEnter: appPublicRefresh,
    component: () => import("../views/app/ranking/run/index.vue"),
    meta: {
      title: "",
    },
  },
  {
    path: "/page/book/rankingList",
    name: "bookRanking",
    beforeEnter: appPublicRefresh,
    component: () => import("../views/app/ranking/book/index.vue"),
    meta: {
      title: "",
    },
  },
];
