// # 公共的业务函数放置
import router from "@/router";
import axios from "axios";
import { localProxyStorage } from "@/utils/storage";
import { ossURL } from "@/config";
// import { logout } from "@/api/auth-server";
import { isIOS, isWeChat } from "@/utils";
import wxOpenApp from "@/utils/weChat/openApp";
// import { isAppOpen } from "@/utils";
import { getUserBaseInfo, shareUserBaseInfo } from "@/api/auth-server";
import { dreamIdByUserPhone } from "@/api/activity";
import { Toast } from "vant";

// 去登录
export const toLogin = () => {
  const route = router.currentRoute.value;
  // let backUrl = route.fullPath; // 有问题暂时不用
  let backUrl = location.pathname + location.search;

  // backUrl 不能是登录页
  let blacklist = ["/login"];
  if (blacklist.includes(location.pathname)) {
    backUrl = "/";
  }

  router.push({
    name: "login",
    query: {
      shareId: route.query.shareId || undefined,
      backUrl: encodeURIComponent(backUrl),
    },
  });
};

// 退出当前登录
// export const signOut = () => {
//   // 直接先清理本地信息，后再通知服务端
//   clearStorage();
//   logout().then();
//   router.push({ name: "login" });
// };

/**
 * 是否已登陆
 */
export const isLogin = () => {
  return localProxyStorage?.user?.token ? true : false;
};

// 高德获取位置
export const getUserLocation = () => {
  return axios.get("https://restapi.amap.com/v3/ip?key=80912f26af58c13f0cfd7d4ff10dfb1e");
};

// 高德经纬度查询
export const getLocationDatils = (longitude, latitude) => {
  return axios.get(
    `https://restapi.amap.com/v3/geocode/regeo?key=80912f26af58c13f0cfd7d4ff10dfb1e&location=${longitude},${latitude}&extensions=all&batch=false`
  );
};

// 高德关键字搜索
export const getLocationInfo = (params) => {
  var url =
    // "https://restapi.amap.com/v3/place/text?key=80912f26af58c13f0cfd7d4ff10dfb1e&offset=10&page=1&extensions=all";
    "https://restapi.amap.com/v3/assistant/inputtips?key=80912f26af58c13f0cfd7d4ff10dfb1e&offset=10&page=1&datatype=poi";

  url = url + urlEncode(params, 3);
  console.log(url);
  //http://baidu.com?a=1&b=2
  return axios.get(url);
};

// 拼接oss地址
export const ossURLJoin = (url) => {
  if (!url) return "";
  let splicer = "/";
  if (url.substring(0, 1) === "/") {
    splicer = "";
  }
  return ossURL + splicer + url;
};

// 解析文章的视频地址
export function parserVideoUrl(url) {
  let REGS = {
    youku: /v.youku.com/, // 优酷网
    tudou: /www.tudou.com/, // 土豆网
    ku6: /v.ku6.com/, // 酷6网
    56: /www.56.com/, // 56视频
    tencent: /v.qq.com/,
    xg: /www.ixigua.com/,
  };

  let parser = {
    youku(url) {
      let reg = new RegExp("https?://v.youku.com/v_show/id_([^/]+)(.html)", "i");
      let res = url.match(reg);
      if (!res) return;
      if (res) {
        return `https://player.youku.com/embed/${res[1]}`;
      }
    },
    tencent(url) {
      let reg = new RegExp("([^/]+).html", "i");
      let res = url.match(reg);
      if (!res) return;
      if (res) {
        return `https://v.qq.com/txp/iframe/player.html?vid=${res[1]}`;
      }
    },
    xg(url) {
      let reg = new RegExp("https?://www.ixigua.com/([^/]+)", "i");
      let res = url.match(reg);
      if (!res) return;
      if (res) {
        return `https://www.ixigua.com/iframe/${res[1]}`;
      }
    },
  };

  let afterParserUrl = null;

  for (let key in REGS) {
    if (REGS[key].test(url) && parser[key]) {
      console.log(key, "视频来源");
      afterParserUrl = parser[key](url);
      break;
    }
  }

  return afterParserUrl;
}

/**
 * param 将要转为URL参数字符串的对象
 * key URL参数字符串的前缀
 * encode true/false 是否进行URL编码,默认为true
 * idx ,循环第几次，用&拼接
 * return URL参数字符串
 */
const urlEncode = (param, idx, key, encode) => {
  if (param == null) return "";
  let paramStr = "";
  let t = typeof param;
  if (t === "string" || t === "number" || t === "boolean") {
    let one_is = idx < 3 ? "?" : "&";
    paramStr += one_is + key + "=" + (encode == null || encode ? encodeURIComponent(param) : param);
  } else {
    for (let i in param) {
      let k = key == null ? i : key + (param instanceof Array ? "[" + i + "]" : "." + i);
      idx++;
      paramStr += urlEncode(param[i], idx, k, encode);
    }
  }
  return paramStr;
};

// 加密手机号码
export const encryptTel = (tel) => {
  if (!tel) return;
  let reg = /^(\d{3})\d{4}(\d{4})$/;
  return tel.replace(reg, "$1****$2");
};

export function iosRefresh(to, form, next) {
  // 解决ios端第一次进入页面，微信分享失效的bug
  if (isIOS() && isWeChat() && to.path !== location.pathname) {
    window.location.href = `${window.location.origin}${to.fullPath}`;
    return;
  }

  next();
}
export function wxRefresh(to, form, next) {
  if (isWeChat()) {
    wxOpenApp();
  }
  next();
}

//调起App的ID
export const appId = "wx8330e3be23db5459";

// 判断 非散客用户 有多个企业时，用户的多个机构身份中 是否包含 链接携带的 机构id
export const saveCurrentHabitOrg = (linkOrgId) => {
  if (!localProxyStorage?.user?.isOrg) {
    localProxyStorage.user = Object.assign(localProxyStorage.user, { isUseOrgId: "0" });
    return;
  }
  let userIsCurrentOrg = false;
  let orgId = localProxyStorage?.user?.orgInfoList[0]?.orgId || "0";
  if (localProxyStorage?.user?.orgInfoList?.length > 0) {
    let temp = localProxyStorage.user.orgInfoList;
    let index = temp.findIndex((item) => {
      return item.orgId === linkOrgId;
    });
    if (index !== -1) {
      userIsCurrentOrg = true;
      orgId = localProxyStorage.user.orgInfoList[index].orgId;
    }
  }
  let obj = { isHabitCurrentOrg: userIsCurrentOrg, isUseOrgId: orgId };
  localProxyStorage.user = Object.assign(localProxyStorage.user, obj);
};

// 处理 backUrl 携带参数
export const handleQuery = (splitUrl, backUrl) => {
  const temp2 = splitUrl[1].split("&");
  let obj = {};
  temp2.forEach((item) => {
    const it = item.split("=");
    obj[it[0]] = it[1];
  });
  // infoOrgId 分享出来的用户、习惯的机构id等
  if (localProxyStorage.infoOrgId) {
    saveCurrentHabitOrg(Number(localProxyStorage.infoOrgId));
  } else {
    if (localProxyStorage?.user?.orgInfoList) {
      let obj = { isUseOrgId: localProxyStorage?.user?.orgInfoList[0]?.orgId || "0" };
      localProxyStorage.user = Object.assign(localProxyStorage.user, obj);
    }
  }
  router.push({
    path: decodeURIComponent(backUrl),
    query: {
      ...obj,
    },
  });
};

// 根据图片背景颜色 获取主色调
export const getImageColor = (url) => {
  const canvas = document.getElementById("myCanvas");
  const clockBg = document.getElementsByClassName("lineaer-bg");
  const context = canvas.getContext("2d");
  let image = new Image();

  image.src = url + "?" + new Date().getTime(); //背景图路径
  image.crossOrigin = "anonymous";

  // let binaryData = [];
  // binaryData.push(url);
  // window.URL = window.URL || window.webkitURL;
  // image.src = window.URL.createObjectURL(new Blob(binaryData));

  // image.src =
  //   "https://img2.baidu.com/it/u=3171247351,649605450&fm=253&fmt=auto&app=138&f=JPEG?w=800&h=500";
  image.onload = function () {
    console.log(image);
    canvas.width = image.width;
    canvas.height = image.height;
    context.drawImage(image, 0, 0, canvas.width, canvas.height);
    // getImageData 跨域
    const temp = context.getImageData(0, 0, image.width, image.height);
    // 获取像素数据
    const data = temp?.data;
    let r = 1;
    let g = 1;
    let b = 1;
    // 取所有像素的平均值
    for (let row = 0; row < image.height; row++) {
      for (let col = 0; col < image.width; col++) {
        if (row == 0) {
          r += data[image.width * row + col];
          g += data[image.width * row + col + 1];
          b += data[image.width * row + col + 2];
        } else {
          r += data[(image.width * row + col) * 4];
          g += data[(image.width * row + col) * 4 + 1];
          b += data[(image.width * row + col) * 4 + 2];
        }
      }
    }
    // 求取平均值
    r /= image.width * image.height;
    g /= image.width * image.height;
    b /= image.width * image.height;
    // 将最终的值取整
    r = Math.round(r);
    g = Math.round(g);
    b = Math.round(b);
    if (!clockBg) return;
    clockBg[0].style.backgroundImage =
      "linear-gradient(180deg, rgb(" + r + "," + g + "," + b + "), transparent";
  };
};
const getDreamIdByUserPhone = async () => {
  let shareDreamId = 0;
  if (localProxyStorage?.user?.userPhone) {
    const res = await dreamIdByUserPhone({ userPhone: localProxyStorage.user.userPhone });
    shareDreamId = res.data;
  }
  localProxyStorage.app = Object.assign(localProxyStorage?.app || {}, {
    shareDreamId,
  });
};

// 321专属
export async function appRefresh(to, form, next) {
  console.log(to.query);
  // isAppOpen();
  // const { token } = to.query;
  try {
    const { token, orgId, orgUserId } = to.query;
    if (token) {
      localProxyStorage.app = Object.assign(localProxyStorage?.app || {}, {
        isAppOpen: true,
      });
      localProxyStorage.user = Object.assign(localProxyStorage?.user || {}, {
        token,
        isUseOrgId: orgId,
        isOrg: true,
        orgUserId,
      });
      const res = await getUserBaseInfo({
        orgId,
        orgUserId,
      });
      localProxyStorage.user = Object.assign(localProxyStorage?.user || {}, res.data);
    } else {
      localProxyStorage.app = Object.assign(localProxyStorage?.app || {}, {
        isAppOpen: false,
      });
      if (localProxyStorage?.user?.token) {
        const userData = await shareUserBaseInfo({
          orgId: localProxyStorage?.user?.isUseOrgId || "0",
          orgUserId: localProxyStorage.user.orgUserId,
        });
        localProxyStorage.user = Object.assign(localProxyStorage?.user || {}, userData.data);
      }
    }
    await getDreamIdByUserPhone();
  } catch (error) {
    console.log(error);
  }
  next();
}

// 通用
export async function appPublicRefresh(to, form, next) {
  // 白屏时的加载状态
  let loginLoading = Toast.loading({
    message: "加载中...",
    duration: 0,
    loadingType: "spinner",
  });
  try {
    const { token, orgId, orgUserId, shareOrgId, shareUserId } = to.query;
    if (token) {
      // 内嵌App处理
      localProxyStorage.app = Object.assign(localProxyStorage?.app || {}, {
        isAppOpen: true,
      });
      localProxyStorage.user = Object.assign(localProxyStorage?.user || {}, {
        token,
        isUseOrgId: orgId,
        isOrg: true,
        orgUserId,
      });
      const res = await getUserBaseInfo({
        orgId,
        orgUserId,
      });
      localProxyStorage.user = Object.assign(localProxyStorage?.user || {}, res.data);
    } else {
      // App外h5处理
      localProxyStorage.app = Object.assign(localProxyStorage?.app || {}, {
        isAppOpen: false,
      });
      // App外部页面分享人数据
      if (shareUserId) {
        const data = await shareUserBaseInfo({
          orgId: shareOrgId || 0,
          orgUserId: shareUserId,
        });
        const userData = data.data;
        localProxyStorage.share = Object.assign(localProxyStorage?.share || {}, {
          ...userData,
          orgId: shareOrgId,
          orgUserId: shareUserId,
        });
      } else {
        localProxyStorage.share = {};
      }
    }
  } catch (error) {
    console.log(error);
  }
  loginLoading.clear();
  next();
}
