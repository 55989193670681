// import { wxRefresh } from "@/common";
export default [
  {
    path: "/page/habit/HabitDetail",
    name: "Habit",
    // beforeEnter: wxRefresh,
    component: () => import("../views/app/habit/index.vue"),
    meta: {
      title: "上进青年",
    },
  },
  {
    path: "/page/habit/selectGroup",
    name: "HabitSelectGroup",
    // beforeEnter: wxRefresh,
    component: () => import("../views/app/habit/select-group.vue"),
    meta: {
      title: "上进青年",
    },
  },
  {
    path: "/page/habit/dataDescription",
    name: "HabitDataDescription",
    component: () => import("../views/app/habit/data-description.vue"),
    meta: {
      title: "上进青年",
    },
  },
];
