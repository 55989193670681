import { appPublicRefresh } from "@/common";

export default [
  {
    path: "/page/invite/index",
    name: "Invite",
    beforeEnter: appPublicRefresh,
    component: () => import("../views/app/invite/index.vue"),
    meta: {
      title: "上进青年",
      keepAlive: true,
    },
  },
  {
    path: "/page/inviteResult/index",
    name: "InviteResult",
    component: () => import("../views/app/invite/result.vue"),
    meta: {
      title: "上进青年",
    },
  },
];
