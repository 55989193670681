// # 放工具函数 业务函数禁止放这边
import { sessionProxyStorage, localProxyStorage } from "@/utils/storage";
import { isLogin } from "@/common/index";
import router from "@/router";
import bridge from "@/utils/bridge";

// import axios from "axios";
// import { Toast } from "vant";

/* eslint-disable */
// 禁止微信浏览器设置网页字体大小

export const banWeChatSetFontSize = function () {
  function handleFontSize() {
    WeixinJSBridge.invoke("setFontSizeCallback", { fontSize: 0 });
    // 重写设置网页字体大小的事件
    WeixinJSBridge.on("menu:setfont", () => {
      WeixinJSBridge.invoke("setFontSizeCallback", { fontSize: 0 });
    });
  }

  if (typeof WeixinJSBridge === "object" && typeof WeixinJSBridge.invoke === "function") {
    handleFontSize();
  } else if (document.addEventListener) {
    document.addEventListener("WeixinJSBridgeReady", handleFontSize, false);
  } else if (document.attachEvent) {
    document.attachEvent("WeixinJSBridgeReady", handleFontSize);
    document.attachEvent("onWeixinJSBridgeReady", handleFontSize);
  }
};
/* eslint-enable */

/**
 * 判断是否为微信浏览器
 * */
export const isWeChat = () => {
  return (
    /MicroMessenger/i.test(window.navigator.userAgent) &&
    !/wxwork/i.test(navigator.userAgent.toLowerCase())
  );
};

/**
 * 判断是否为微信浏览器且获取微信SDK成功
 * */
export const isWeChatEnv = () => {
  return (
    /MicroMessenger/i.test(window.navigator.userAgent) &&
    !/wxwork/i.test(navigator.userAgent.toLowerCase()) &&
    localProxyStorage?.auth?.authStatus
  );
};

/**
 * 是否是苹果系统
 * */
export const isIOS = function () {
  return /ip(hone|od|ad)/i.test(navigator.userAgent);
};

/**
 * 是否是安卓系统
 * */
export const isAndroid = function () {
  return navigator.userAgent.indexOf("Android") > -1 || navigator.userAgent.indexOf("Adr") > -1;
};

// uuid 生成函数
export function uuid() {
  const s = [];
  const hexDigits = "0123456789abcdef";
  for (let i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = "-";

  return s.join("");
}

export function clearStorage() {
  let shareId = sessionProxyStorage?.shareId;
  localStorage.clear();
  sessionStorage.clear();

  if (shareId) {
    sessionProxyStorage.shareId = shareId;
  }
}

export function byteConvert(size) {
  if (!size) return "";
  let num = 1024; //byte
  if (size < num) return size + "B";

  if (size < Math.pow(num, 2)) {
    return (size / num).toFixed(2) + "KB";
  }

  if (size < Math.pow(num, 3)) {
    return (size / Math.pow(num, 2)).toFixed(2) + "MB";
  }

  if (size < Math.pow(num, 4)) {
    return (size / Math.pow(num, 3)).toFixed(2) + "G";
  }

  return (size / Math.pow(num, 4)).toFixed(2) + "T";
}

export const sliceStr = (str, start, end) => {
  if (typeof str === "string") {
    return str.slice(start, end);
  }
  return "";
};

// 唤起app失败处理
export const handleAppError = () => {
  if (isIOS()) {
    window.location.href = "https://apps.apple.com/cn/app/id1639250598";
  } else {
    window.location.href = "https://a.app.qq.com/o/simple.jsp?pkgname=cn.yzou.youth";
  }
};

// 判断 h5 内置浏览器 - 是企微内置浏览器时才返回true
export const checkH5InnerUserAgent = () => {
  const u = navigator.userAgent.toLowerCase();
  const isIosQQ = isIOS() && / QQ/i.test(u);
  const isAndroidQQ = isAndroid() && /MQQBrowser/i.test(u) && / QQ/i.test(u.split("MQQBrowser"));
  const isdingTalk = u.indexOf("dingtalk") > -1; // 是否 钉钉浏览器
  const isComWx = /wxwork/i.test(u); // 是否 企业微信
  const isWxBrowser = u.indexOf("micromessenger") !== -1; //是否 微信内置浏览器
  const isQQBrowser = isIosQQ || isAndroidQQ; // 是否 qq 内置浏览器
  let flag = false;
  if (localProxyStorage?.auth?.authStatus) {
    // handleAppError();
    window.location.href = "http://shangjinqingnian.com/sjapp";
    return flag;
  }
  if (isComWx || isdingTalk || isWxBrowser || isQQBrowser) {
    // flag = false;
    newWin("http://shangjinqingnian.com/sjapp", "app-schema");
    return flag;
  }
  // TODO 请求下载地址
  // if (isAndroid()) {
  // 安卓跳转
  // const url = `app/versionInfo/androidDownloadAddress`;
  // let appFileUrl = "";
  // let testUrl = "http://pre-sjqn-geteway.shangjinqingnian.com/";
  // axios.get(testUrl + url).then((res) => {
  //   // console.log(res);
  //   if (res && res.data?.code === 200) {
  //     appFileUrl = res.data.data.appFileUrl;
  //     window.open(appFileUrl);
  //   } else {
  //     Toast(res.data?.message || "网络连接异常,请稍后再试!");
  //   }
  // });
  // window.open("https://www.shangjinqingnian.com/sjapp");
  //   window.location.href = "https://www.shangjinqingnian.com/sjapp";
  // }
  // if (isIOS()) {
  //   const url =
  //     "https://apps.apple.com/cn/app/%E4%B8%8A%E8%BF%9B%E9%9D%92%E5%B9%B4-%E8%B7%91%E6%AD%A5%E8%AF%BB%E4%B9%A6%E4%B8%8A%E8%BF%9B%E4%B9%A0%E6%83%AF%E5%85%BB%E6%88%90%E7%A4%BE%E5%8C%BA/id1639250598";
  //   window.open(url);
  // }
  handleOpenApp();
  return flag;
};

// window.open浏览器弹出新窗口被拦截解决方案-a标签
export const newWin = (url, id) => {
  var a = document.createElement("a");
  a.setAttribute("href", url);
  a.setAttribute("target", "_blank");
  a.setAttribute("id", id);
  // 防止反复添加
  if (!document.getElementById(id)) {
    document.body.appendChild(a);
  }
  a.click();
};

// h5判断是否能唤起app
export const handleOpenApp = () => {
  // app 地址
  // const appSchema = localProxyStorage?.app?.extinfo || "youthapp://youth.cn/page/app/Main?tab=mine";
  // const appSchema = `youthapp://youth.cn/page/app/Main?tab=habit`;
  if (isIOS()) {
    let loadDateTime = new Date();
    // window.location = appSchema; //schema链接或者universal link
    // newWin(appSchema, "app-schema");
    window.location = "https://apps.apple.com/cn/app/id1639250598"; //ios下载地址
    window.setTimeout(function () {
      //如果没有安装app,便会执行setTimeout跳转下载页
      const timeOutDateTime = new Date();
      if (timeOutDateTime - loadDateTime < 5000) {
        // newWin(
        //   "https://apps.apple.com/cn/app/%E4%B8%8A%E8%BF%9B%E9%9D%92%E5%B9%B4-%E8%B7%91%E6%AD%A5%E8%AF%BB%E4%B9%A6%E4%B8%8A%E8%BF%9B%E4%B9%A0%E6%83%AF%E5%85%BB%E6%88%90%E7%A4%BE%E5%8C%BA/id1639250598",
        //   "app-schema"
        // );
        window.location = "https://apps.apple.com/cn/app/id1639250598"; //ios下载地址
      } else {
        window.close();
      }
    }, 1500);
  } else if (isAndroid()) {
    try {
      // window.location = appSchema; //schema链接或者universal link
      // newWin(appSchema, "app-schema");
      newWin("https://a.app.qq.com/o/simple.jsp?pkgname=cn.yzou.youth", "app-schema");
      // window.setTimeout(function () {
      //   newWin("https://a.app.qq.com/o/simple.jsp?pkgname=cn.yzou.youth", "app-schema");
      // }, 1500);
    } catch (e) {
      console.log(e);
    }
  }
};

// 未登录跳转到登录页
export const pushLogin = () => {
  const backUrl = location.pathname + location?.search;
  const isLoginPath =
    location.pathname.includes("smsCode") ||
    location.pathname.includes("login") ||
    location.pathname.includes("name");
  console.log(backUrl);
  if (!isLogin() && !isLoginPath) {
    router.push({
      path: "/smsCode",
      query: {
        backUrl: encodeURIComponent(backUrl),
      },
    });
    return true;
  } else {
    return false;
  }
};

// 是否app打开
export const isAppOpen = () => {
  localProxyStorage.app = Object.assign(localProxyStorage?.app || {}, { isAppOpen: false });
  try {
    bridge
      .callHandler("isAppOpen")
      .then((res) => {
        localProxyStorage.app = Object.assign(localProxyStorage?.app || {}, {
          isAppOpen: res?.appOpen,
        });
      })
      .catch((error) => {
        console.log(error);
        localProxyStorage.app = Object.assign(localProxyStorage?.app || {}, { isAppOpen: false });
      });
  } catch (error) {
    console.log(error);
    localProxyStorage.app = Object.assign(localProxyStorage?.app || {}, { isAppOpen: false });
  }
  return localProxyStorage?.app?.isAppOpen;
};

// 图片转base64
export const getBase64Image = (imgUrl) => {
  return new Promise((resolve) => {
    if (!imgUrl) {
      resolve();
    }

    let image = new Image();
    image.crossOrigin = "Anonymous";
    image.src = imgUrl;
    console.log("image", image);

    image.onload = () => {
      const canvas = document.createElement("canvas");
      console.log("image onload", canvas);
      canvas.width = image.width;
      canvas.height = image.height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(image, 0, 0, image.width, image.height);
      const ext = image.src.substring(image.src.lastIndexOf(".") + 1).toLowerCase();
      const dataURL = canvas.toDataURL("image/" + ext);
      resolve(dataURL);
    };

    image.onerror = () => {
      console.log("⭕：", `图片加载失败，路径为[${imgUrl}]`);
      resolve();
    };
  });
};

// 节流
// 节流是一段时间内执行一次
export function throttle(fun, time) {
  let t1 = 0; //初始时间
  return function () {
    let t2 = new Date(); //当前时间
    if (t2 - t1 > time) {
      fun.apply(this, arguments);
      t1 = t2;
    }
  };
}

// 防抖
export const debounce = (fn, delay) => {
  let timer;
  return function () {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      fn();
    }, delay);
  };
};

// 大屏适配字体尺寸
export const fitChartSize = (size, defalteWidth = 1920) => {
  let clientWidth =
    window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  if (!clientWidth) return size;
  let scale = clientWidth / defalteWidth;
  return Number((size * scale).toFixed(3));
};

/**
 * @description: 键盘弹起，输入框被遮挡
 */
export function judgeInput() {
  if (isIOS()) {
    window.addEventListener("focusin", function () {
      console.log(1 + document.activeElement.tagName);
      if (
        document.activeElement.tagName === "INPUT" ||
        document.activeElement.tagName === "TEXTAREA"
      ) {
        setTimeout(function () {
          document.documentElement.scrollTop = document.body.scrollHeight;
        }, 0);
      }
    });
  } else {
    window.addEventListener("resize", function () {
      console.log(2 + document.activeElement.tagName);
      if (
        document.activeElement.tagName === "INPUT" ||
        document.activeElement.tagName === "TEXTAREA"
      ) {
        setTimeout(function () {
          document.activeElement.scrollIntoView();
        }, 0);
      }
    });
  }
}

/**
 * @description: base64 转为Blob
 */
export function base64ToBlob(base64Data) {
  // const dataArr = base64Data.split(","); // 根据,来分隔
  // const dataJoin = `data:image/png;base64,${base64Data}`;
  // 获取文件类型。使用正则捕获 image/jpeg
  const imageType = "png";
  // 使用atob() 将base64 转为文本文件
  const textData = window.atob(base64Data);
  // 创建一个二进制数据缓冲区，可以理解为一个数组
  const arrayBuffer = new ArrayBuffer(textData.length);
  // 创建一个类型化数组对象，可以理解为上面的数组的成员，给这个对象赋值就会放到上面的数组中。
  const uint8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < textData.length; i++) {
    uint8Array[i] = textData.charCodeAt(i); // 将文本文件转为UTF-16的ASCII, 放到类型化数组对象中
  }
  // 返回两个值，一个Blob对象，一个图片格式（如jpeg）
  return [new Blob([arrayBuffer], { type: imageType }), imageType.slice(6)];
}

// 转为formData
export function toFormData(base64Data) {
  const [imageBlob, imageType] = base64ToBlob(base64Data); // 获取处理好的Blob 和文件类型
  const formData = new FormData();
  formData.append("file", imageBlob, `${Date.now()}.${imageType}`); // 添加到表单，传入文件名
  return formData;
}
