// 活动
import http from "@/utils/axios";
import { localProxyStorage } from "@/utils/storage";

// 获取活动状态 2023 321活动
export const getActivityStatus = (params) => {
  return http.get("/app/free/dream/activityStatus", {
    params,
    requestOptions: { isMsg: localProxyStorage?.app?.isAppOpen },
  });
};
// 是否参加活动 2023 321活动
export const isJoinActivity = (params) => {
  return http.get("/app/free/dream/isJoinActivity", {
    params,
    requestOptions: { isMsg: localProxyStorage?.app?.isAppOpen },
  });
};
// 报名活动 2023 321活动
export const joinActivity = (data) => {
  return http.post("/app/free/dream/joinActivity", data, {
    requestOptions: { isMsg: localProxyStorage?.app?.isAppOpen },
  });
};

// 是否发布愿望 2023 321活动
export const isPublishDream = (params) => {
  params = Object.assign(params || {}, { source: "0" });
  return http.get("/app/free/dream/isPublishDream", {
    params,
  });
};
// 愿望详情列表展示 2023 321活动
export const dreamDetailsList = (params) => {
  params = Object.assign(params || {}, { source: "0" });
  return http.get("/app/free/dream/dreamDetailsList", {
    params,
  });
};
// 愿望列表展示 2023 321活动
export const dreamList = (params) => {
  params = Object.assign(params || {}, { source: "0" });
  return http.get("/app/free/dream/dreamList", {
    params,
  });
};
// 点赞愿望
export const likeDream = (data) => {
  data = Object.assign(data || {}, { source: "0" });
  return http.post("/app/free/dream/likeDream", data);
};
// 愿望类型列表
export const dreamTypeList = (params) => {
  return http.get("/app/free/dream/publishDreamTypeList", { params });
};
// 愿望id换愿望信息
export const dreamInfoByDreamId = (params) => {
  params = Object.assign(params || {}, { source: "0" });
  return http.get("/app/free/dream/dreamInfoByDreamId", {
    params,
  });
};
// 手机号换愿望id
export const dreamIdByUserPhone = (params) => {
  params = Object.assign(params || {}, { source: "0" });
  return http.get("/app/free/dream/getDreamIdByUserPhone", {
    params,
  });
};

// led大屏 - 获取弹幕 2023 321活动
export const ledDanMuList = (params) => {
  return http.get("/app/free/dream/led/barrage", {
    params,
    requestOptions: { isMsg: false },
  });
};
// led大屏 获取系统时间 2023 321活动
export const ledSystemTime = (params) => {
  return http.get("/app/free/dream/led/sysTime", {
    params,
    requestOptions: { isMsg: false },
  });
};
// led大屏 获取榜单列表 2023 321活动
export const ledRankList = (params) => {
  return http.get("/app/free/dream/led/top/list", {
    params,
    requestOptions: { isMsg: false },
  });
};
// led大屏 获取梦想参与人数 2023 321活动
export const ledDreamJoinNum = (params) => {
  return http.get("/app/free/dream/led/user/total", {
    params,
    requestOptions: { isMsg: false },
  });
};

/** 候鸟基金活动接口start **/
// 获取活动信息
export const getBirdInfo = (params) => {
  return http.get("/app/free/bird/v1/get/config", {
    params,
  });
};
// 获取战队排名
export const getBirdGroup = (params) => {
  return http.get("/app/free/bird/v1/get/group", {
    params,
  });
};
// 获取跑量排名
export const getBirdRun = (params) => {
  return http.get("/app/free/bird/v1/get/user", {
    params,
  });
};
// 获取我的战队
export const getMyGroup = (params) => {
  return http.get("/app/free/bird/v1/get/groupInfo", {
    params,
  });
};
// 获取我的跑量
export const getMyRun = (params) => {
  return http.get("/app/free/bird/v1/get/runInfo", {
    params,
  });
};
// 绑定邀约
export const handleInvite = (data) => {
  return http.post("/app/free/bird/v1/invite", data, {
    requestOptions: {
      isMsg: false,
    },
  });
};
/** 候鸟基金活动接口end **/

/** 上进盛典接口start **/

// 获取 上进盛典 首页数据
export const getCeremonyIndex = (params) => {
  return http.get("/app/common/sjPeople/indexData", {
    params,
    requestOptions: { isMsg: localProxyStorage?.app?.isAppOpen },
  });
};

// 获取 上进盛典 类型用户数据
export const getCeremonyList = (params) => {
  return http.post("/app/common/sjPeople/get", params, {
    requestOptions: { isMsg: localProxyStorage?.app?.isAppOpen },
  });
};

/** 上进盛典接口end **/
