import { appPublicRefresh } from "@/common";
// 成长体系
export default [
  {
    path: "/page/growth/commonRule",
    name: "growthCommonRule",
    beforeEnter: appPublicRefresh,
    component: () => import("../views/app/growth/rule/common-rule.vue"),
    meta: {
      title: "上进青年",
    },
  },
  {
    path: "/page/growth/benefitDetail",
    name: "growthBenefitDetail",
    beforeEnter: appPublicRefresh,
    component: () => import("../views/app/growth/rule/benefit-detail.vue"),
    meta: {
      title: "上进青年",
    },
  },
  {
    path: "/page/growth/addressPage",
    name: "growthAddressPage",
    beforeEnter: appPublicRefresh,
    component: () => import("../views/app/growth/rule/address-page.vue"),
    meta: {
      title: "上进青年",
    },
  },
];
