<!--
 * @description:
 * @param :
 * @return:
-->
<template>
  <div :class="{ app: isH5 }">
    <!-- <inviter-top v-if="$route.meta.isHideShare" /> -->
    <!-- <inviter-top /> -->
    <!-- <return-home-btn v-if="$route.meta.isShowHomeBtn" /> -->
    <router-view v-slot="{ Component }">
      <keep-alive :include="KeepAliveStore.keepAlive" :max="10">
        <component :is="Component" />
      </keep-alive>
    </router-view>
  </div>
</template>

<script setup>
import { watch, ref, onUnmounted } from "vue";
import { useRoute } from "vue-router";
// import InviterTop from "@/components/inviter-top";
// import ReturnHomeBtn from "@/components/return-home-btn";
import { banWeChatSetFontSize } from "@/utils";
import useKeepAliveStore from "@/store/keepAlive";
import { localProxyStorage } from "@/utils/storage";

const route = useRoute();
let KeepAliveStore = useKeepAliveStore();
const isH5 = ref(route.name !== "FestivalLed");
watch(
  route,
  (to) => {
    if (to.meta.keepAlive) {
      KeepAliveStore.updateKeepAlive(to.name);
    }
  },
  {
    immediate: true,
  }
);

// 禁止微信浏览器设置字体大小
banWeChatSetFontSize();

// 销毁应用时清空缓存埋点列表
onUnmounted(() => {
  localProxyStorage.pointData = [];
});
</script>

<style lang="scss"></style>
