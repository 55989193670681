<template>
  <!-- 企业微信打开提示 引导到浏览器 -->
  <div class="share-bg" v-if="show" @click="close">
    <img src="@/assets/images/share.png" alt="" />
    <p>请选择默认浏览器打开</p>
  </div>
</template>
<script setup>
import { ref, watch } from "vue";
const props = defineProps({
  showFlag: {
    type: Boolean,
    default: false,
  },
});
const show = ref(false);
const emit = defineEmits(["close-mask"]);
watch(
  () => props.showFlag,
  (newVal) => {
    show.value = newVal;
  }
);

const close = () => {
  console.log("close");
  emit("close-mask", false);
};
</script>

<style lang="scss" scoped>
.share-bg {
  background-color: rgba(0, 0, 0, 0.8);
  width: 3.75rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  img {
    position: absolute;
    width: 0.46rem;
    height: 0.34rem;
    top: 0.3rem;
    right: 0.25rem;
  }
  p {
    position: absolute;
    width: 1.92rem;
    font-size: 0.16rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 0.22rem;
    text-align: center;
    right: 0.25rem;
    top: 0.8rem;
  }
}
</style>
