<template>
  <div class="join-box iphone-inset-bottom">
    <div class="join-left">
      <div class="image">
        <img v-if="shareInfo?.userLogo" :src="shareInfo?.userLogo" alt="group" />
        <!-- 默认头像已改，无需处理 -->
        <!-- <img v-else src="@/assets/images/dynamic/default-avater.png" alt="group" /> -->
      </div>
      <div class="text-box">
        <div class="title-text">
          <span v-if="groupId"
            >{{ shareInfo?.userName }}邀请你加入{{ shareInfo?.groupName }}战队</span
          >
          <span v-else>{{ shareInfo?.userName }}邀请你加入习惯</span>
        </div>
      </div>
    </div>
    <div class="join-right">
      <div class="join-btn" @click="joinGroup">
        {{ title }}
        <div v-if="isWx && title === '已加入'">
          <wx-open-launch-app
            class="youth-app"
            @launch="handleLaunch"
            @error="handleError"
            :appid="appId"
            :extinfo="extinfo"
          >
            <component :is="'script'" type="text/wxtag-template">
              <div style="width: 60px; height: 56px"></div>
            </component>
          </wx-open-launch-app>
        </div>
      </div>
    </div>
  </div>
  <!-- 固定底部适配iPhone刘海盒子 -->
  <!-- <div class="blank"></div>  -->
</template>
<script>
export default { name: "FixedFooter" };
</script>
<script setup>
import { watch, ref, computed } from "vue";
import { isWeChatEnv, checkH5InnerUserAgent } from "@/utils";
import { appId } from "@/common/index";
import useOpenAppStore from "@/store/openApp";
const openAppStore = useOpenAppStore(); // 存储app跳转路由

openAppStore.setExtinfo(`youthapp://youth.cn/page/app/Main?tab=habit`);

const extinfo = computed(() => {
  return openAppStore.getExtinfo();
});
const showFlag = ref(false); // 是否显示 引导下载 遮罩
const downloadApp = () => {
  const openMask = checkH5InnerUserAgent();
  if (openMask) {
    showFlag.value = true;
  }
};
const handleError = (val) => {
  downloadApp();
  console.log("error", val, val.detail, extinfo);
};
const handleLaunch = (val, val1, val2) => {
  console.log("success", val, val1, val2, extinfo);
};
const shareInfo = ref({});
const isWx = ref(isWeChatEnv());
const props = defineProps({
  title: {
    type: String,
    default: "加入战队",
  },
  info: {
    type: Object,
    default: () => {
      return {};
    },
  },
  groupId: {
    type: String,
    default: "",
  },
});

watch(
  () => props.info,
  (newVal) => {
    shareInfo.value = newVal;
  },
  {
    immediate: true,
  }
);

const emit = defineEmits(["change"]);
const joinGroup = () => {
  emit("change", true);
};
</script>

<style lang="scss" scoped>
.join-box {
  width: 3.75rem;
  height: 0.66rem;
  position: fixed;
  // left: 0;
  // right: 0;
  // margin-bottom: constant(safe-area-inset-bottom);
  // margin-bottom: env(safe-area-inset-bottom);
  bottom: 0;
  padding: 0 0.14rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f65c15;
  z-index: 2;
  .join-left {
    display: flex;
    .image {
      width: 0.36rem;
      height: 0.36rem;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
      }
    }
    .text-box {
      margin-left: 0.08rem;
    }
    .title-text {
      color: #ffffff;
      font-size: 0.15rem;
      line-height: 0.36rem;
      max-width: 2.22rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .join-right {
    .join-btn {
      cursor: pointer;
      padding: 0.07rem 0.11rem;
      background: #ffffff;
      border-radius: 0.2rem;
      color: #f65c15;
      font-size: 0.13rem;
      font-weight: 600;
      position: relative;
      .youth-app {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
}
// iphone 底部刘海 fixed 元素的适配
.blank {
  position: fixed;
  bottom: 0;
  height: 0;
  width: 100%;
  height: constant(safe-area-inset-bottom);
  height: env(safe-area-inset-bottom);
  background-color: #f65c15;
}
</style>
