import { createRouter, createWebHistory } from "vue-router";
// import { sessionProxyStorage } from "@/utils/storage";
import { isLogin, toLogin } from "@/common";
import { iosRefresh } from "@/common";
import userRouters from "./user";
import habitRouters from "./habit";
import dynamicRouters from "./dynamic";
import accountRouters from "./account";
import activityRouters from "./activity";
import inviteRouters from "./invite";
import rankingRouters from "./ranking";
import sociallyRouters from "./socially";
import growthRouters from "./growth";
import questionRouters from "./question";
import testRouters from "./test";

const routes = [
  {
    path: "/",
    name: "home",
    meta: {
      keepAlive: true,
    },
    beforeEnter: iosRefresh,
    component: () => import("../views/home/home-view"),
  },
  ...userRouters,
  ...habitRouters,
  ...dynamicRouters,
  ...accountRouters,
  ...activityRouters,
  ...inviteRouters,
  ...rankingRouters,
  ...sociallyRouters,
  ...growthRouters,
  ...questionRouters,
  ...testRouters,
  // {
  //   path: "/test",
  //   name: "test",
  //   component: () => import("../views/Test"),
  // },
  // {
  //   path: "/help",
  //   name: "help",
  //   component: () => import("../views/help/help"),
  //   meta: {
  //     title: "帮助中心",
  //   },
  // },
  // {
  //   path: "/editor",
  //   name: "editor",
  //   component: () => import("../views/editor"),
  //   meta: {
  //     title: "写文章",
  //   },
  // },
  {
    path: "/:pathMatch(.*)*",
    component: () => import("../views/404"),
    meta: {
      title: "页面不存在~",
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  // 解决ios端第一次进入页面，分享失效的bug
  // if (isIOS() && isWeChat() && to.path !== location.pathname) {
  //   console.log("刷新页面了");
  //   window.location.href = `${window.location.origin}${to.fullPath}`;
  // }

  // 判断是否需要鉴权
  if (to.meta?.requiresAuth) {
    if (!isLogin()) {
      toLogin();
    }
  }

  // 设置网页标题
  document.title = to.meta?.title || "上进青年";

  // 存储一个全局的 entryUrl 解决ios微信授权问题
  // if (window.entryUrl === undefined || window.entryUrl === "") {
  //   window.entryUrl = location.href.split("#")[0];
  // }

  // // 存放邀约id
  // if (to.query.shareId) {
  //   sessionProxyStorage.shareId = to.query.shareId;
  // }

  // 所有正式环境的http链接自动跳转至https
  // if (window.location.origin === "http://www.ijiaolian.com") {
  //   window.location.href = window.location.href.replace("http://", "https://");
  // }

  next();
});

export default router;
