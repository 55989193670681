import DownloadApp from "./download-app";
import FixedFooter from "./fixed-footer";

// 常用的才放进来
const components = [DownloadApp, FixedFooter];

const install = (app) => {
  components.forEach((component) => {
    app.component(component.name, component); // 每个组件必需提供 name 属性
  });
};

export default {
  install,
};
