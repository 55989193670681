import { appRefresh, appPublicRefresh } from "@/common";

export default [
  {
    path: "/activity/culturalFestival24/index",
    name: "Festival",
    beforeEnter: appRefresh,
    component: () => import("../views/activity/cultural-festival-23/index.vue"),
    meta: {
      title: "321上进文化节",
    },
  },
  {
    path: "/activity/culturalFestival23/rank",
    name: "ActivityRank",
    component: () => import("../views/activity/cultural-festival-23/rank.vue"),
    meta: {
      title: "321上进文化节",
    },
  },
  {
    path: "/activity/culturalFestival23/led/index",
    name: "FestivalLed",
    component: () => import("../views/activity/cultural-festival-23/led/index.vue"),
    meta: {
      title: "321上进文化节",
    },
  },
  {
    path: "/activity/culturalFestival23/index",
    name: "WelfareFund",
    beforeEnter: appPublicRefresh,
    component: () => import("../views/activity/welfare-fund/index.vue"),
    meta: {
      title: "上进益公里",
    },
  },
  {
    path: "/activity/ceremony24/index",
    name: "Ceremony",
    beforeEnter: appPublicRefresh,
    component: () => import("../views/activity/ceremony-24/index.vue"),
    meta: {
      title: "上进者盛典",
    },
  },
];
