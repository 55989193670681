import { appPublicRefresh } from "@/common";
// 排行榜
export default [
  {
    path: "/page/socially/activityDetails",
    name: "sociallyDetails",
    beforeEnter: appPublicRefresh,
    component: () => import("../views/app/socially/activity-details/index.vue"),
    meta: {
      title: "",
    },
  },
  {
    path: "/page/socially/doVolunteer",
    name: "volunteer",
    beforeEnter: appPublicRefresh,
    component: () => import("../views/app/socially/volunteer/index.vue"),
    meta: {
      title: "做志愿者",
    },
  },
];
