import { isIOS, isAndroid } from "@/utils";

/**
 * 调用原生方法
 * @param {String} methodName 原生方法名
 * @param {Object} params 传给原生的参数
 */
const callHandler = (methodName, params = {}) => {
  const data = {
    methodName,
    params,
  };
  const promise = new Promise((resolve, reject) => {
    try {
      if (isAndroid()) {
        const jsonString = window.android.nativeMethodExecute(JSON.stringify(data));
        const response = JSON.parse(jsonString);
        console.log("接收安卓返回值:", response);
        resolve(response);
      } else if (isIOS()) {
        setupWebViewJavascriptBridge(function (bridge) {
          console.log("call-handler:", bridge, data);
          if (!window.WebViewJavascriptBridge) {
            window.WebViewJavascriptBridge = bridge;
          }
          bridge.callHandler("nativeMethodExecute", data, (jsonString) => {
            const response = JSON.parse(jsonString);
            console.log("接收ios返回值:", response);
            resolve(response);
          });
        });
      }
    } catch (error) {
      reject(error);
    }
  });
  return Promise.race([promise]);
};

/**
 * 我们注册方法给原生调用
 * @param {String} methodName 我们组册的方法名
 * @param {Function} cb 回调函数，需要执行的业务
 * @param {Object} param 返回给原生的数据
 */
const registerHandler = (methodName, cb) => {
  const promise = new Promise((resolve, reject) => {
    try {
      if (isIOS()) {
        setupWebViewJavascriptBridge(function (bridge) {
          console.log("register-bridge:", bridge);
          if (!window.WebViewJavascriptBridge) {
            window.WebViewJavascriptBridge = bridge;
          }
          bridge.registerHandler("nativeMethodCall", function (data, responseCallback) {
            console.log("register-data", data);
            cb(JSON.parse(data), responseCallback);
            resolve(JSON.parse(data), responseCallback);
          });
        });
      } else {
        window[methodName] = (data) => {
          cb && cb(JSON.parse(data));
          resolve(JSON.parse(data));
        };
      }
    } catch (error) {
      reject(error);
    }
    return Promise.race([promise]);
  });
};

/**
 * ios与h5通信方法
 * @param {Function} callback 回调函数
 */
function setupWebViewJavascriptBridge(callback) {
  console.log("setupWebViewJavascriptBridge 打印:", window.WebViewJavascriptBridge);
  try {
    if (window.WebViewJavascriptBridge) {
      // console.log("WebViewJavascriptBridge", window.WebViewJavascriptBridge);
      return callback(window.WebViewJavascriptBridge);
    }
    if (window.WVJBCallbacks) {
      // console.log("WVJBCallbacks", window.WVJBCallbacks);
      return window.WVJBCallbacks.push(callback);
    }
    window.WKWVJBCallbacks = [callback];
    window.webkit.messageHandlers.iOS_Native_InjectJavascript.postMessage(null);
  } catch (error) {
    console.log(error);
  }
  // window.WVJBCallbacks = [callback];
  // let WVJBIframe = document.createElement("iframe");
  // WVJBIframe.style.display = "none";
  // WVJBIframe.src = "https://__bridge_loaded__";
  // document.documentElement.appendChild(WVJBIframe);
  // setTimeout(() => {
  //   document.documentElement.removeChild(WVJBIframe);
  // }, 0);
}
/**
 * registerhandler(name, callback) { // ios注册方法
    setupWebViewJavascriptBridge(function (bridge) {
      bridge.registerHandler(name, function (data, responseCallback) {
        callback(data, responseCallback)
      })
    })
  }
 */

export default {
  callHandler,
  registerHandler,
};
