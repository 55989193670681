import { defineStore } from "pinia";

const useKeepAliveStore = defineStore({
  id: "KeepAlive", // id必填，且需要唯一
  state: () => {
    return {
      keepAlive: [],
    };
  },
  actions: {
    updateKeepAlive(name) {
      if (!this.keepAlive.includes(name)) {
        this.keepAlive.push(name);
      }
    },
    removeKeepAlive(name) {
      let index = this.keepAlive.indexOf(name);
      if (index > -1) {
        this.keepAlive.splice(index, 1);
      }
    },
  },
});

export default useKeepAliveStore;
