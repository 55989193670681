import { appPublicRefresh } from "@/common";
// 成长体系
export default [
  {
    path: "/page/question/detail",
    name: "questionDetail",
    beforeEnter: appPublicRefresh,
    component: () => import("../views/app/question/detail/index.vue"),
    meta: {
      title: "上进青年",
    },
  },
  {
    path: "/page/question/history",
    name: "questionHistory",
    beforeEnter: appPublicRefresh,
    component: () => import("../views/app/question/history/index.vue"),
    meta: {
      title: "历史反馈",
    },
  },
  {
    path: "/page/question/formDetail",
    name: "questionFormDetail",
    beforeEnter: appPublicRefresh,
    component: () => import("../views/app/question/form/formDetail.vue"),
    meta: {
      title: "",
    },
  },
  {
    path: "/page/question/formSumbit",
    name: "questionFormSumbit",
    beforeEnter: appPublicRefresh,
    component: () => import("../views/app/question/form/formSumbit.vue"),
    meta: {
      title: "问题反馈",
    },
  },
  {
    path: "/page/question/submitResult",
    name: "questionSubmitResult",
    beforeEnter: appPublicRefresh,
    component: () => import("../views/app/question/form/submitResult.vue"),
    meta: {
      title: "",
    },
  },
];
