// import { createApp } from "vue";
// import AppVue from "../App.vue";
// let App = createApp(AppVue);
import {
  Button,
  List,
  Cell,
  CellGroup,
  Swipe,
  SwipeItem,
  Form,
  Field,
  RadioGroup,
  Radio,
  Uploader,
  Tab,
  Tabs,
  TreeSelect,
  Checkbox,
  CheckboxGroup,
  Cascader,
  Popup,
  Col,
  Row,
  DatetimePicker,
  Tag,
  Image,
  ActionBar,
  ActionBarIcon,
  ActionBarButton,
  Icon,
  Rate,
  Empty,
  Step,
  Steps,
  ConfigProvider,
  IndexBar,
  IndexAnchor,
  Popover,
  DropdownMenu,
  DropdownItem,
  Picker,
  Divider,
  Loading,
  Skeleton,
  Overlay,
  ActionSheet,
  NavBar,
  PullRefresh,
  Progress,
} from "vant";

const components = [
  Button,
  List,
  Cell,
  CellGroup,
  Swipe,
  SwipeItem, // 需要的就导入
  Form,
  Field,
  RadioGroup,
  Radio,
  Uploader,
  Tab,
  Tabs,
  TreeSelect,
  Checkbox,
  CheckboxGroup,
  Cascader,
  Popup,
  Col,
  Row,
  DatetimePicker,
  Tag,
  Image,
  ActionBar,
  ActionBarIcon,
  ActionBarButton,
  Icon,
  Rate,
  Empty,
  Step,
  Steps,
  ConfigProvider,
  IndexBar,
  IndexAnchor,
  Popover,
  DropdownMenu,
  DropdownItem,
  Picker,
  Divider,
  Loading,
  Skeleton,
  Overlay,
  ActionSheet,
  NavBar,
  PullRefresh,
  Progress,
];

export default {
  install(vue) {
    for (const component of components) {
      vue.component(component.name, component);
    }
  },
};
